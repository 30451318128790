<template>
  <div class="card">
    <h2 class="card-title my-3">Or, scan the QR code</h2>
    <div class="card-body d-flex">
      <div class="container d-flex justify-content-center align-items-center">
        <div class="row">
          <QrCodeVue :value="code" :level="level" :size="240" :render-as="renderAs" class="align-items-center" />
          <small v-if="ttl" class="mt-6 text-muted">The QR Code is valid for {{ ttl }}s</small>
          <small>
            <a :href="code" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Copied" @click.prevent="copy()">
              copy link
            </a>
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import QrCodeVue from 'qrcode.vue';
import type { Level, RenderAs } from 'qrcode.vue';
import { fetchEventSource } from '@microsoft/fetch-event-source';

import { Tooltip } from 'bootstrap';

import { api } from '@/services/backend';

const router = useRouter();
const route = useRoute();

const code = ref('');
const level = ref<Level>('L');
const renderAs = ref<RenderAs>('svg');
const ttl = ref<number | null>(null);

let tooltip: Tooltip;

interface Expiry {
  iat: number;
  exp: number;
}

const copy = async () => {
  await navigator.clipboard.writeText(code.value);
  setTimeout(() => tooltip.hide(), 1000);
};

let ctrl = new AbortController();
const abort = () => {
  ctrl.abort();
  ctrl = new AbortController();
}

// const refresh = ctrl.abort;
// ttl.value = 3 * 60 + 1; // so it counts down from 180 to 1, not 179 to 0;

onMounted(async () => {
  tooltip = new Tooltip(document.querySelector('[data-bs-toggle="tooltip"]')!, {
    trigger: 'click',
    delay: { show: 0, hide: 150 },
  });

  setInterval(() => ttl.value !== null && (ttl.value -= 1), 1000);
  let loggedin = false;

  while (!loggedin) {
    const uuid = crypto.randomUUID();
    code.value = `${window.location.origin}/qrcode-login/${uuid}`;
    await fetchEventSource(`/qrcode-login-subscribe?id=${uuid}`, {
      signal: ctrl.signal,
      async onopen(resp) { // eslint-disable-line @typescript-eslint/no-misused-promises
        const expiry = resp.headers.get('Expiry');
        if (resp.ok && expiry) {
          const { exp } = JSON.parse(expiry) as Expiry;
          const now = Math.floor(Date.now() / 1000);
          ttl.value = exp - now;
          setTimeout(abort, ttl.value * 1000);
        }
        return new Promise(resolve => resolve());
      },
      async onmessage(ev) { // eslint-disable-line @typescript-eslint/no-misused-promises
        switch (ev.event) {
          case 'loggedin': {
            loggedin = true; // should be set after router.push() onmessage isn't blocking
            const r = await api.get(`/qrcode-login-session/${uuid}`).res();

            const url = `${window.location.origin}/auth/callback/qrcode`;
            if (route.query.redirect) {
              window.sessionStorage.setItem('redirect', route.query.redirect as string);
            } else {
              window.sessionStorage.removeItem('redirect');
            }
            if (r.ok && r.redirected && r.url === url) {
              await router.push({ path: '/auth/callback/qrcode' });
            }
            abort();
            break;
          }
          case 'timeout': {
            abort();
            break;
          }
        }
      },
    });
  }
});
</script>
