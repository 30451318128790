<template>
  <!-- eslint-disable vuejs-accessibility/label-has-for -->
  <div
    v-if="state.showPurchaseDediModal"
    id="purchaseDediModal"
    class="modal fade"
    data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-labelledby="purchaseDediLabel" aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="purchaseDediLabel" class="modal-title">Please select a billing cycle</h5>
          <button
            type="button"
            class="btn-close stm-btn-add-dedi-cancel"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="dismissNewDedicatedServerModal"
          />
        </div>
        <div class="modal-body">
          <div class="form-floating mt-3">
            <div class="form-check">
              <div class="card h-100 my-2">
                <div class="card-body pb-4">
                  <div class="card-title">
                    <input
                      id="newDediatedServerMonthly"
                      v-model="state.newDedicatedServer.timeInterval"
                      value="monthly"
                      class="form-check-input"
                      type="radio"
                      :checked="!subscription.productName.match(/year/)"
                    >
                    <label class="form-check-label stm-btn-add-dedi-monthly" for="newDediatedServerMonthly">
                      <h5>Monthly Dedicated Server</h5>
                      <small>$120 billed monthly</small>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="subscription.productName.match(/year/)" class="form-check">
              <div class="card h-100 my-2">
                <div class="card-body pb-4">
                  <div class="card-title">
                    <input
                      id="newDediatedServerYearly"
                      v-model="state.newDedicatedServer.timeInterval"
                      value="yearly"
                      class="form-check-input"
                      type="radio"
                      checked
                    >
                    <label class="form-check-label stm-btn-add-dedi-yearly" for="newDediatedServerYearly">
                      <h5>Yearly Dedicated Server</h5>
                      <small>$900 billed yearly</small>
                    </label>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary stm-btn-add-dedi-cancel"
            data-bs-dismiss="modal"
            @click="dismissNewDedicatedServerModal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-speedify-blue stm-btn-add-dedi-confirm"
            :class="{ disabled: state.newDedicatedServer.timeInterval === '' }"
            data-bs-dismiss="modal"
            @click="purchaseDedicatedServerHandler('confirmDediModal')"
          >
            Purchase Dedicated Server
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--Purchase confirmation modal-->
  <div
    v-if="state.showConfirmDediModal"
    id="confirmDediModal"
    class="modal fade"
    data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="confirmDediLabel" aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="purchaseDediLabel" class="modal-title">Review Order</h5>
        </div>
        <div class="modal-body">
          <div class="card h-100">
            <div class="card-body pb-4">
              <div class="card-title d-flex justify-content-between">
                  <div>
                    <p v-if="state.newDedicatedServer.timeInterval=='yearly'">Yearly Dedicated Server</p>
                    <p v-if="state.newDedicatedServer.timeInterval=='monthly'">Monthly Dedicated Server</p>
                  </div>
                  <div>
                    <strong v-if="state.newDedicatedServer.timeInterval=='yearly'">$900.00</strong>
                    <strong v-if="state.newDedicatedServer.timeInterval=='monthly'">$120.00</strong>
                  </div>
              </div>
            </div>
          </div>
          <div class="p-3">
            <small>
              Our system administrators will contact you to choose a location for your server. &nbsp;
              <a
                href="https://speedify.com/for-teams/dedicated-vpn-servers/"
                target="_blank"
                rel="noopener noreferrer"
                class="text-decoration-none stm-btn-dedi-more"
              >
                Learn more
              </a>
            </small>
          </div>
        </div>
        <div class="modal-footer">
          <div v-if="state.newDedicatedServer.success" class="alert alert-success stm-msg-add-dedi-success" role="alert">
            A new dedicated server has successfully been purchased!
            We have notified our system administrators and you should be hearing back from them soon!
          </div>
          <div v-if="state.newDedicatedServer.failure" class="alert alert-danger stm-msg-add-dedi-failure" role="alert">
            Something went wrong when purchasing your dedicated server. Please try again later or contact support@speedify.com
            <hr>
            {{ state.newDedicatedServer.errorContext }}
          </div>
          <div v-if="subscription.storeType !== 'REC'" class="alert alert-danger stm-msg-add-dedi-non-rec-failure" role="alert">
            It seems like your subscription was purchased on our previous payment processor.
            In order to proceed with your dedicated server purchase, please contact support@speedify.com
          </div>
          <button
            v-if="!state.newDedicatedServer.success"
            type="button"
            class="btn btn-secondary stm-btn-add-dedi-cancel"
            data-bs-dismiss="modal"
            @click="dismissNewDedicatedServerModal"
          >
            Cancel
          </button>
          <button
            v-if="!state.newDedicatedServer.success"
            type="button"
            class="btn btn-speedify-blue stm-btn-add-dedi-confirm-final"
            :class="{
              disabled: state.newDedicatedServer.timeInterval === '' || subscription.storeType !== 'REC'
            }"
            @click="purchaseNewDedicatedServer()"
          >
            <span v-if="state.newDedicatedServer.pending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Confirm Purchase
          </button>
          <button
            v-if="state.newDedicatedServer.success"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="dismissNewDedicatedServerModal"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-end my-3">
    <div class="col-lg-6">
      <h2 class="">Dedicated Servers</h2>
      <small>
        View usage and performance analytics. &nbsp;
        <a
          href="https://speedify.com/for-teams/dedicated-vpn-servers/"
          target="_blank"
          rel="noopener noreferrer"
          class="text-decoration-none stm-btn-dedi-more"
        >
          Learn more
        </a>
      </small>
    </div>
    <button
      v-if="isSubscriptionOwner(subscription)"
      type="button"
      class="btn btn-speedify-blue text-decoration-none stm-btn-add-dedi-existing-purchase-flow"
      @click="handleShowModal('purchaseDediModal')"
    >
      <i class="fas fa-plus"></i>&nbsp;Add a Dedicated Server
    </button>
  </div>

  <DedicatedServerPromotion v-if="promotionVisible" :subscription="subscription" @showModal="handleShowModal" />
  <DedicatedServersInfo v-else :subscription="subscription" @showModal="handleShowModal" />
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue';

import { useUserStore } from '@/store/user';
import DedicatedServerPromotion from '@/components/DedicatedServerPromotion.vue';
import DedicatedServersInfo from '@/components/DedicatedServersInfo.vue';
import { purchaseDedicatedServer } from '@/services/backend';
import { isOwner as isSubscriptionOwner  } from '@/services/subscription';
import { showModal } from '@/services/modal';
import { Subscription } from '@/model';

const userStore = useUserStore();

const props = defineProps<{ subscription: Subscription }>()

// eslint-disable-next-line vue/no-setup-props-destructure
const state = reactive({
  newDedicatedServer: {
    // eslint-disable-next-line vue/no-setup-props-destructure
    timeInterval: props.subscription.productName.match(/year/i) ? 'yearly' : 'monthly',
    success: false,
    failure: false,
    errorContext: '',
    pending: false,
  },
  showPurchaseDediModal: false,
  purchaseNewDedicatedServer: false,
  showConfirmDediModal: false,
});

const dismissNewDedicatedServerModal = () => {
  state.newDedicatedServer.timeInterval = props.subscription.productName.match(/yearly/i) ? 'yearly' : 'monthly';
  state.newDedicatedServer.success = false;
  state.newDedicatedServer.failure = false;
  state.newDedicatedServer.errorContext = '';
  state.purchaseNewDedicatedServer = false;
  state.showConfirmDediModal = false;
};

const purchaseNewDedicatedServer = async () => {
  state.newDedicatedServer.pending = true;
  const response = await purchaseDedicatedServer(props.subscription.referenceId, state.newDedicatedServer.timeInterval);
  state.newDedicatedServer.pending = false;

  if (response.error) {
    state.newDedicatedServer.failure = true;
    // @ts-ignore
    state.newDedicatedServer.errorContext = response.message;
    return;
  }

  state.newDedicatedServer.success = true;
};

const purchaseDedicatedServerHandler = (elementID: string) => {
  const supportedStores = ['REC'];
  if (supportedStores.includes(props.subscription.storeType)) {
    handleShowModal(elementID);
  } else {
    const cartRedirection = `${import.meta.env.VITE_CART_URL}/checkout/?plan=speedify-dedicated-server-${state.newDedicatedServer.timeInterval}`;
    window.location.replace(cartRedirection);
  }
}

const handleShowModal = (elementID: string) => {
  // @ts-ignore
  state[`show${elementID.charAt(0).toUpperCase()}${elementID.slice(1)}`] = true;
  showModal(elementID);
}

const promotionVisible = computed(() => {
  if (props.subscription.type === 'teams' || props.subscription.type === 'families') {
    if (props.subscription.ownerEmail.toLowerCase() === userStore.email.toLowerCase()) {
      return userStore.dedicatedServerQuantity === 0;
    }

    // we need to look at this so that admins (users that don't have ownership of the
    // servers subscriptions can still see the server panel
    // TODO: since the team members can't see the owner's subscriptions (including servers)
    // it is impossible to find out if the dedicated server sub is active *IF* there are straggling
    // team servers associated with the team in the database. we hope the operations team handles
    // this case like we expect
    return (userStore.teamServers?.filter(s => s.teamId === props.subscription.team!.teamId))?.length === 0;
  }
  if (props.subscription.type === 'individual') {
    return userStore.dedicatedServerQuantity === 0;
  }
  return false;
});
</script>
