import { useUserStore } from '@/store/user';
import { Subscription, Team } from '../model';

export const isOwner = (subscription: Subscription) => {
  const { email } = useUserStore();

  if (subscription.type === 'individual' || subscription.type.match(/server/i) || subscription.type.match(/router/i)) {
    return true;
  }

  if (subscription.ownerEmail.toLowerCase() === email.toLowerCase()) {
    return true;
  }

  return false;
};

export const isOwnerOrAdmin = (subscription: Subscription) => {
  const { email } = useUserStore();

  if (subscription.type === 'individual' || subscription.type.match(/server/i)) {
    return true;
  }

  if (subscription.ownerEmail.toLowerCase() === email.toLowerCase()) {
    return true;
  }

  const isAdmin = subscription.team!.teamMembers.find((m) => m.role === 'admin' && m.email === email);
  return isAdmin;
};

export const getProductCardColor = (productName: string) => {
  if (productName.toLowerCase().match(/team/)) {
    return 'border-danger';
  }
  if (productName.toLowerCase().match(/families/)) {
    return 'border-primary';
  }
  if (productName.match(/server/i)) {
    return 'border-success';
  }
  return 'border-info';
};

export const humanReadableProductName = (productName: string) => {
  if (productName.toLowerCase().match(/team/)) {
    return 'Speedify for Teams';
  }
  if (productName.toLowerCase().match(/families/)) {
    return 'Speedify for Families';
  }
  if (productName.match(/server/i)) {
    return 'Speedify Dedicated Server';
  }
  if (productName.match(/router/i)) {
    return 'Speedify Router Subscription';
  }
  return 'Speedify Individual';
};

export const subscriptionRelationship = (subscription: Subscription) => {
  const { email } = useUserStore();

  if (subscription.type === 'individual' || subscription.type.match(/server/i) || subscription.type.match(/router/i)) {
    return 'Owner';
  }

  const memberDetails = subscription.team!.teamMembers.find((m) => email.toLowerCase() === m.email.toLowerCase())!;

  if (subscription.type === 'families' && memberDetails.role === 'admin') {
    return 'Owner';
  }

  if (subscription.type === 'families') {
    return 'Member';
  }

  if (subscription.type === 'teams' && email.toLowerCase() === subscription.ownerEmail.toLowerCase()) {
    return 'Owner';
  }
  return memberDetails.role.replace(/^\w/, (c) => c.toUpperCase());
};

export const subscriptionRenewalInfo = (subscription: Subscription) => {
  let renewalTimeFrame;

  // Subscription has been cancelled
  if (subscription.cancelable === 0) {
    return 'This subscription will not renew after the term end';
  }

  if (subscription.productName.match(/monthly/i)) {
    renewalTimeFrame = 'monthly';
  } else if (subscription.productName.match(/yearly/i)) {
    renewalTimeFrame = 'yearly';
  } else if (subscription.productName.match(/nonrenew/i)) {
    renewalTimeFrame = undefined;
  } else if (subscription.productName.match(/3month|3year|threeyear|2year|twoyear/i)) {
    return `Renews on ${new Date(subscription.nextPeriodDate ?? 0).toDateString()}`;
  }

  if (renewalTimeFrame) {
    return `Renews ${renewalTimeFrame} on ${new Date(subscription.nextPeriodDate ?? 0).toDateString()}`;
  }

  return 'Subscription does not renew';
};

export const printSubscriptionInfoLine = (subscription: Subscription) => {
  const relationship = subscriptionRelationship(subscription);
  let line = '';
  if (relationship !== 'Owner') {
    line = `Owned by ${subscription.ownerEmail} | `;
  }
  line = `${line}${subscriptionRenewalInfo(subscription)}`;
  return line;
};

export const teamSubscriptionQuickInfo = (subscription: Subscription) => {
  let info = '';

  if (subscription.type !== 'individual' && !subscription.type.match(/server/i) && !subscription.type.match(/router/i)) {
    info = `${subscription.quantity} Seats | ${subscription.team!.teamMembers.length} Seats Assigned`;
    if (subscription.type === 'teams') {
      info = `${info} | ${subscription.team!.teamServers.length || 0} Dedicated Servers`;
    }
  }

  return info;
};

export const routerSubscriptionQuickInfo = (subscription: Subscription) => {
  const userStore = useUserStore();
  let info = '';

  const associatedRouters = userStore.routers.filter((r) => r.routerReferenceId === subscription.referenceId);

  if (subscription.type === 'router') {
    const hasTeamSub = userStore.subscriptions.some(s => s.type === 'teams' || s.type === 'families');
    // The `licenses assigned` text should really just be shown for teams due to the team/router
    // association flow
    // but we don't need it if it's just a single router or an individual + router/promo
    if (hasTeamSub) {
      info = `${subscription.quantity} License(s) | ${associatedRouters?.length || 0} License(s) Assigned`;
    } else {
      info = `${subscription.quantity} License(s)`;
    }
  }

  return info;
};

export const subscriptionPeriod = (productName: string) => {
  if (productName.toLowerCase().match(/monthly/)) {
    return 'monthly ';
  }
  if (productName.toLowerCase().match(/yearly/)) {
    return 'yearly ';
  }
  if (productName.toLowerCase().match(/3year/)) {
    return '3 years ';
  }
  return '';
};

export const isCancelable = (subscription: Subscription) => {
  const nonRenewCodes = ['nonrenew', 'non-renew'];

  const isNonRenewing = nonRenewCodes.some((e) => subscription.productName.match(e));
  if (isNonRenewing) {
    return false;
  }

  if (subscription.cancelable === 0) {
    return false;
  }

  return true;
};

// TODO: do it when our recurly is ready for pausing a sub
export const isPausable = (subscription: Subscription) => false && !!subscription.type.match(/router/i);

export const isUncancelable = (subscription: Subscription) => {
  const nonRenewCodes = ['nonrenew', 'non-renew'];

  const isNonRenewing = nonRenewCodes.some((e) => subscription.productName.match(e));
  if (isNonRenewing) {
    return false;
  }

  if (subscription.cancelable === 1) {
    return false;
  }

  return true;
};

export const shortRenewalSummary = (subscription: Subscription) => {
  if (subscription.productName.match(/nonrenew/i) || subscription.cancelable === 0) {
    return new Date(0);
  }
  return new Date(subscription.nextPeriodDate ?? 0);
};

export const getSeats = (subscription: { team: Team, quantity: number }) => {
  const { team } = subscription;
  if (!team) throw new Error('unimplemented');
  const active = team.teamMembers.length;
  const total = subscription.quantity;
  const inactive = total - active;
  return { total, active, inactive };
};

export const singularSubscriptionType = {
  teams: 'team',
  families: 'family',
  individual: 'individual',
  router: 'router',
};
