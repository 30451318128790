<template>
  <LoadingComponent v-if="state.pendingStoreRefresh" text="Loading your account data" />
  <slot v-else />
</template>

<script setup lang="ts">
import { onBeforeMount, reactive } from 'vue';
import { useRouter } from 'vue-router';

// TODO: styling
import LoadingComponent from '@/components/LoadingComponent.vue';
import { useUserStore } from '@/store/user';
import { getToken } from '@/services/backend';

const router = useRouter();
const userStore = useUserStore();
const state = reactive({
  pendingStoreRefresh: true,
  needsub: false,
});

onBeforeMount(async () => {
  try {
    await userStore.refresh();
    state.pendingStoreRefresh = false;

    const numRouterSubs = userStore.routerQty;
    const payload = await getToken();
    if (payload?.role === 'router' && numRouterSubs < userStore.routers.length) {
      return await router.push('/welcome');
    }

    if (!userStore.subscriptions.length) {
      return await router.push('/welcome');
    }
  } catch (err) {
    console.error('Error getting user/subscription', err);
  }
});
</script>
